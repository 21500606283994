<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <div v-else>
      <div class="mb-5 text-right">
        <create-update-catalogs-component :create-without-selected-game-data="true" @updateParent="fetch" />
      </div>
      <div class="cards">
        <div class="card" v-for="(game, index) in datas" :key="index"
          :style="`background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${baseWebImgURL+game.imageUrl}); background-repeat: no-repeat; background-size: cover;`">
          <div class="card-image">
            <img :src="baseWebImgURL+game.imageUrl"/>
          </div>
          <div class="card-content">
            <div class="card-content-gamename">{{ game.name }}</div>
            <div class="card-content-counter">{{ game.catalogCount }} item</div>
            <div><button @click="$router.push(`/catalogs/${game.slug}`)" class="card-content-button">Lihat Item <v-icon x-small>mdi-arrow-right</v-icon></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingScreen from "../components/LoadingScreen.vue";
import CreateUpdateCatalogsComponent from "../components/CreateUpdateCatalogsComponent.vue";

export default {
  name: "Catalogs",

  components: { LoadingScreen, CreateUpdateCatalogsComponent },

  data() {
    return {
      datas: [],
      isLoading: true,
      baseWebImgURL: process.env.VUE_APP_IMAGE_URL,
    };
  },

  methods: {
    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let url = `${process.env.VUE_APP_API_URL}/v2/catalog/game`;

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.isLoading = false;

          this.datas = response.data.result;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.card {
  display: flex;
  gap: 10px;
  position: relative;
  border-radius: 15px;
}
.card-image > img {
  width: 176px;
  height: 100%;
  border-radius: 15px 0 0 15px;
}
.card-content {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  text-align: right;
}
.card-content-gamename {
  font-size: 22px;
  font-weight: 800;
}
.card-content-counter {
  font-size: 12px;
}
.card-content-button {
  font-size: 12px;
  background: #FAC55C;
  color: #141414;
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: 800;
  margin-top: 5px;
}
</style>