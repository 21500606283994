<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="!updateData" v-bind="attrs" v-on="on" color="#f8b32b" style="color: white">
        <v-icon>mdi-plus</v-icon>
        Tambah Item
      </v-btn>
      <v-btn v-else v-bind="attrs" v-on="on" color="#f8b32b" style="color: white">
        Edit
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>{{ !updateData ? "Tambah" : "Edit" }} Item Penjualan</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="formCreate" v-model="valid" lazy-validation>
        <v-card-text>
          <div class="d-flex flex-column" style="margin-top: 24px">
            <label class="form-title">Gambar Item</label>
            <v-file-input
              accept="image/*"
              name="image"
              :rules="!updateData && fieldRequired"
              label="Pilih File"
              v-model="image"
            ></v-file-input>

            <label class="form-title">Nama Item</label>
            <v-text-field
              hide-details
              label="Masukkan Nama Item"
              name="catalogName"
              single-line
              outlined
              v-model="catalogName"
              :rules="fieldRequired"
            ></v-text-field>

            <label class="form-title">Harga Modal</label>
            <v-text-field
              hide-details
              label="Rata-rata harga tertinggi untuk meminimalisir kerugian"
              name="catalogCapitalPrice"
              single-line
              outlined
              v-model="catalogCapitalPrice"
              :rules="fieldRequired"
              type="number"
            ></v-text-field>

            <label class="form-title">Harga Jual</label>
            <v-text-field
              hide-details
              label="Harga Jual"
              name="catalogSellingPrice"
              single-line
              outlined
              v-model="catalogSellingPrice"
              :rules="fieldRequired"
              type="number"
            ></v-text-field>

            <label class="form-title">Harga Sebelum Diskon</label>
            <v-text-field
              hide-details
              label="Harga Jual"
              name="catalogBeforeDiscountPrice"
              single-line
              outlined
              v-model="catalogBeforeDiscountPrice"
              :rules="fieldRequired"
              type="number"
            ></v-text-field>

            <template v-if="createWithoutSelectedGameData">
              <label class="form-title">Game</label>
              <v-select
                :items="gameList"
                name="gameTypeId"
                v-model="gameTypeId"
                item-text="name"
                item-value="gameTypeId"
                :rules="fieldRequired"
                dense
              ></v-select>
            </template>
            
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="btn-posting"
            color="#f8b32b"
            style="
              color: white;
              padding: 14px;
              margin-bottom: 24px;
              margin-top: 24px;
            "
            @click="onStore"
            :loading="isLoading"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import * as imageConversion from "image-conversion";

export default {
  name: "CreateGameListComponent",
  props: {
    gameTypeId: {
      type: Number,
      default: null
    },
    updateData: {
      type: Object,
      default: null
    },
    createWithoutSelectedGameData: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false,
    fieldRequired: [(v) => !!v || "This field is required"],
    hidden: false,
    image: "",
    catalogName: "",
    catalogCapitalPrice: "",
    catalogBeforeDiscountPrice: "",
    catalogSellingPrice: "",
    modal: false,
    dialog: false,
    isLoading: false,
    gameList: []
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      axios
        .create({ withCredentials: false })
        .get(
          `${process.env.VUE_APP_API_URL}/v2/admin/game_list`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then((response) => {
          this.gameList = response.data.result;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
        });
    },
    
    async onStore() {
      if (this.$refs.formCreate.validate()) {
        let config = {};
        if (this.$store.getters.getToken) {
          config = {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          };
        }

        const form = new FormData();
        const file = this.image;
        if (file) {
          const convert = await imageConversion.compressAccurately(file, 300);
          const new_file = new File([convert], file.name, {
            type: convert.type,
          });
          form.append("image", new_file);
        }

        form.append("catalogName", this.catalogName);
        form.append("catalogCapitalPrice", this.catalogCapitalPrice);
        form.append("catalogBeforeDiscountPrice", this.catalogBeforeDiscountPrice);
        form.append("catalogSellingPrice", this.catalogSellingPrice);
        form.append("gameTypeId", this.gameTypeId);

        this.isLoading = true;
        const apiUrl = `${process.env.VUE_APP_API_URL}/v2/catalog/${!this.updateData ? 'create' : this.updateData.catalogId+'/update'}`;
        try {
          await axios
            .create({ withCredentials: false })
            .post(apiUrl, form, config);
          this.dialog = false;
          this.$toast.success("Aksi Berhasil");
          this.$emit("updateParent", "update");
        } catch (error) {
          //nothing
        }

        this.isLoading = false;
      }
    },
    doUpdateData () {
      if (!this.updateData) return;
      this.catalogName = this.updateData.catalogName,
      this.catalogCapitalPrice = this.updateData.catalogCapitalPrice,
      this.catalogBeforeDiscountPrice = this.updateData.catalogBeforeDiscountPrice,
      this.catalogSellingPrice = this.updateData.catalogSellingPrice
    }
  },
  watch: {
    dialog() {
      this.doUpdateData();
    }
  }
};
</script>

<style>
.form-title {
  font-size: 16px;
  font-weight: 400;
  color: #141414;
  margin-top: 24px;
  margin-bottom: 8px;
}
.btn-posting {
  background-color: #f8b32b;
}
</style>
